import { FaBath, FaMapMarkerAlt } from "react-icons/fa";
import { FaHouse } from "react-icons/fa6";
import { IoIosBed } from "react-icons/io";
import { Link } from "react-router-dom";

export default function Property({ propiedades }) {
  if (!propiedades) {
    console.error("El objeto propiedades no está definido:", propiedades);
    return <p>Error: No se pudo cargar la propiedad.</p>;
  }

  const {
    id,
    imagenPortada,
    tipo,
    operacion,
    precio,
    moneda,
    direccion,
    ciudad,
    ambientes,
    dormitorios,
    baños,
  } = propiedades;

  return (
    <div className="max-w-sm mx-auto overflow-hidden rounded-lg shadow-md my-7 hover:shadow-lg">
      <Link to={`/propiedad/${id}`}>
        <div>
          {imagenPortada && (
            <img
              className="h-56 transition-transform w-96 hover:transform hover:scale-105"
              src={imagenPortada}
              alt={tipo || "Propiedad"}
            />
          )}
        </div>
        {operacion && (
          <p className="absolute ml-6 mt-[-13em] bg-primary text-white px-2 py-1 rounded-md">
            {operacion.charAt(0).toUpperCase() + operacion.slice(1)}
          </p>
        )}
        {tipo && (
          <p className="absolute ml-6 mt-[-2em] bg-white text-primary px-2 py-1 rounded-t">
            {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
          </p>
        )}
        <div className="px-6 py-4">
          {precio && moneda && (
            <p className="text-xl font-bold text-primary">
              ${precio} {moneda}
            </p>
          )}

          {tipo && operacion && (
            <div className="mb-2 text-xl font-bold w-fit hover:text-primary">
              {tipo.charAt(0).toUpperCase() + tipo.slice(1)} en {operacion}
            </div>
          )}

          {direccion && ciudad && (
            <p className="flex items-center text-base text-gray-700">
              <FaMapMarkerAlt className="mr-3 text-primary" />
              {direccion}, {ciudad}
            </p>
          )}
        </div>
        <hr />
        <div className="flex justify-between px-6 pt-2 pb-1">
          {ambientes && ambientes > 0 && (
            <p className="flex items-center text-base">
              <FaHouse className="mr-2 text-primary" />
              {ambientes > 1
                ? `${ambientes} ambientes`
                : "1 ambiente"}
            </p>
          )}

          {dormitorios && dormitorios > 0 && (
            <p className="flex items-center text-base">
              <IoIosBed className="mr-2 text-primary" />
              {dormitorios > 1
                ? `${dormitorios} dormitorios`
                : "1 dormitorio"}
            </p>
          )}
        </div>
        <div className="flex justify-between px-6 pb-1">
          {baños && baños > 0 && (
            <p className="flex items-center text-base">
              <FaBath className="mr-2 text-primary" />
              {baños > 1 ? `${baños} baños` : "1 baño"}
            </p>
          )}
        </div>
      </Link>
    </div>
  );
}
